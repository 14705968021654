var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'layer_comm',
    {
      active : _vm.isShowLayer,
      layer_comm_bottom : !_vm.isFull,
      layer_comm_full : _vm.isFull,
      layer_comm_no_head : !_vm.hasHead,
      layer_comm_scroll_full : _vm.isScrollFull,
      layer_comm_dragable : _vm.isDragable,
      layer_comm_openDrag : _vm.isOpenResult
    }
  ],style:(_vm.cssProps),on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onClickClose()}}},[_c('div',{ref:"innerLayerComm",staticClass:"inner_layer_comm"},[(_vm.isDragable)?_c('div',{staticClass:"area_drag",on:{"mousedown":_vm.onListStart,"touchstart":_vm.onListStart,"mousemove":_vm.onListMove,"touchmove":_vm.onListMove,"mouseup":_vm.onListEnd,"mouseleave":_vm.onListEnd,"touchend":_vm.onListEnd,"touchcancle":_vm.onListEnd}},[_c('span',{staticClass:"icon_drag"})]):_vm._e(),(_vm.hasHead)?_c('div',{staticClass:"head_drop"},[(_vm.title)?_c('strong',{staticClass:"tit_drop"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.isCloseBtn)?_c('button',{staticClass:"btn_close",on:{"click":function($event){return _vm.onClickClose()}}},[_c('IconSvg',{attrs:{"iconName":"cross","size":"24","iconColor":_vm.isFull ? '#232323' : '#A6A4A4'}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"body_drop"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }