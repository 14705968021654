const state = {
  isToastPopup: false,
  toastMsg: null,
  toastType: ''
}

const getters = {
	getIsToastPopup: (state) => state.isToastPopup,
	getIsToastMsg: (state) => state.toastMsg,
	getIsToastType: (state) => state.toastType,
}

const actions = {
  fetchToastStart({ commit }, params) {
    commit('START_TOAST_MUTATION', params);
  },
  fetchToastEnd({ commit }, params) {
    commit('END_TOAST_MUTATION');
  },
}

const mutations = {
  START_TOAST_MUTATION(state, payload) {
    if(typeof(payload) === 'string'){
      state.toastMsg = payload;
    }else{
      state.toastMsg = payload.msg;
      state.toastType = payload.type;
    }
    state.isToastPopup = true;
  },
  END_TOAST_MUTATION(state, payload) {
    state.isToastPopup = false;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}