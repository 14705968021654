import poData from './Lemontree_English.json';


for (const [key, value] of Object.entries(poData)) {
  let valueSync = value
  // \n -> <br>
  if(value.indexOf('\n') > -1){
    valueSync = value.replaceAll('\n','<br>');
  }
  // {{value}} -> %s
  if(valueSync.indexOf('{{') > -1 && valueSync.indexOf('}}') > -1){
    let valueArray = valueSync.split('{{');
    valueArray = valueArray.map(item => {
      if(item.indexOf('}}') > -1){
        const cutValue = item.split('}}');
        return '%s'+cutValue[1];
      }else{
        return item;
      }
    });
    const result = valueArray.join('');
    valueSync = result;
  }
  poData[key] = valueSync
}
export default poData;
// extension
// Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) =>  pattern.replace('%s',value), this);
};