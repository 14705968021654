import Vue from 'vue'
import App from './App.vue'
import router from './router/';
import routerPath from './router/routerPath.ts';
import poData from './constants/poData.js';
import store from './store/index';
import apiPath from './services/apiPath';
import './filter/index';

Vue.config.productionTip = false

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

Vue.prototype.$routerPath = routerPath;
Vue.prototype.$apiPath = apiPath;
Vue.prototype.$poData = poData;

const userAgent = navigator.userAgent.toLowerCase() || '';
let varUA;
if ( userAgent.indexOf('android') > -1) {
  varUA = 'ANDROID';
}else if ( userAgent.indexOf("iphone") > -1||userAgent.indexOf("ipad") > -1||userAgent.indexOf("ipod") > -1||userAgent.indexOf("mac") > -1 ) {
  varUA = 'IOS';
}else{
  varUA = 'ETC';
}
Vue.prototype.$varUA = varUA;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
