import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const modules = {};
const requireModule = require.context('./modules', false, /\.js$/);

// 모듈 자동 생성
requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return;
  const moduleName = fileName.replace(/(\.\/|\.js)/gi, '');
  modules[moduleName] = {
    ...requireModule(fileName).default
  }
})

export default new Vuex.Store({
    state:{
      defaultInstalls: [
        'Node Version >= 14.0',
        'NPM Version >= 6.0',
        'Vue Cli Version >= 4.0'
      ],
      browsers: [
        'IE 10 이상',
        '전 세계 브라우저 사용 1% 이상',
        '지원 중단되지 않은 브라우저'
      ]
    },
    mutations: {},
    actions: {},
    getters: {},
    modules
})
