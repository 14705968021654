<template>
  <div
    :class="[
      'layer_comm',
      {
        active : isShowLayer,
        layer_comm_bottom : !isFull,
        layer_comm_full : isFull,
        layer_comm_no_head : !hasHead,
        layer_comm_scroll_full : isScrollFull,
        layer_comm_dragable : isDragable,
        layer_comm_openDrag : isOpenResult
      }
    ]"
    :style="cssProps"
    @click.self="onClickClose()">
    <div class="inner_layer_comm" ref="innerLayerComm">
      <div
        v-if="isDragable"
        @mousedown="onListStart"
        @touchstart="onListStart"
        @mousemove="onListMove"
        @touchmove="onListMove"
        @mouseup="onListEnd"
        @mouseleave="onListEnd"
        @touchend="onListEnd"
        @touchcancle="onListEnd"
        class="area_drag"><span class="icon_drag"></span></div>
      <div
        v-if="hasHead"
        class="head_drop">
        <strong v-if="title" class="tit_drop">{{ title }}</strong>
        <button
          v-if="isCloseBtn"
          class="btn_close"
          @click="onClickClose()">
          <IconSvg iconName="cross" size="24" :iconColor="isFull ? '#232323' : '#A6A4A4'"/>
        </button>
      </div>
      <div class="body_drop">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';
import BottomSheetMixin from '@/mixins/BottomSheetMixin';

import { mapGetters } from 'vuex';

export default {
  name: 'Layer',
  mixins:[ BottomSheetMixin ],
  components: {
    IconSvg
  },
  props:{
    title: String,
    isCloseBtn: {
      type: Boolean,
      default: true
    },
    isFull: {
      type: Boolean,
      default: false
    },
    isScrollFull: {
      type: Boolean,
      default: false
    },
    isDragable:{
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      isShowLayer: false,
      layrInnerHeight: null,
      isOpenResult:false,

      bodyOverflowBeforeMount: null,
    }
  },
  beforeMount(){
    this.isShowLayer = false;
    // document.body.style.overflow = "hidden";
  },
  mounted(){
    this.bodyOverflowBeforeMount = document.body.style.overflow;

    document.body.style.overflow = "hidden";

    this.$nextTick(function () {
      this.setBottomSheetHeight();
    });
    if(!this.isFull){
      const _this = this;
      this.timeoutID0 = setTimeout(function(){
        _this.isShowLayer = true;
        this.timeoutID0 = null;
        _this.setDragBottomSheet();
      }, 50);
    }else{
      this.isShowLayer = true;
    }
  },
  beforeDestroy(){
    if (this.bodyOverflowBeforeMount === 'hidden') {
      return;
    }

    document.body.style.overflow = this.bodyOverflowBeforeMount || "";
  },
  computed: {
    ...mapGetters('commonViewport', [ 'getWindowHeight' ]),
    layerMaxHeight(){
      if(this.isFull){
        return this.getWindowHeight -54
      }
      if( ( !this.title && !this.isCloseBtn ) || this.isScrollFull ){
        return this.getWindowHeight -100 - 32
      }
      else {
        return this.getWindowHeight -100 - 68 - 32
      }
    },
    hasHead(){
      return this.title || this.isCloseBtn
    },
    cssProps(){
      var obj = {};
      obj = {
        '--height': `${(this.layerMaxHeight)}px`,
        // '--innerHeight': `${(this.layrInnerHeight ? this.layrInnerHeight : this.layerMaxHeight) *-1}px`
      }
      return obj;
    },
  },
  methods:{
    onClickClose(){
      document.body.style.overflow = "";
      this.$emit('onClickClose');
    },
    setBottomSheetHeight(){
      this.layrInnerHeight = this.$refs.innerLayerComm.offsetHeight;
    },
    setDragBottomSheet(){
      if(this.isDragable && !this.mobileBottomSheet){
        this.bottomSheetMixinInit()
      }
      if(!this.isDragable){
        if(this.mobileBottomSheet){
          this.mobileBottomSheet = false;
        }
        this.$refs.innerLayerComm.style.top = ""
      }
    }
  }
}
</script>

<style scoped>
.layer_comm{position:fixed;top:0;bottom:0;left:0;right:0;z-index:300;width:100%;backdrop-filter:blur(1px);height:100%;max-height:inherit}
.layer_comm .inner_layer_comm{position:absolute;bottom:0;left:0;right:0;width:100%;max-height:0;overflow:hidden;border:0 none;border-radius:8px 8px 0 0;box-sizing:border-box;background-color:#fff}

.head_drop{padding:24px 60px 16px 20px}
.head_drop .tit_drop{display:block;font-weight:700;font-size:20px;line-height:28px;color:#111}
.head_drop .btn_close{position:absolute;top:26px;right:20px}

.layer_comm_no_head .inner_layer_comm{padding-top:24px}
.body_drop{overflow:hidden;overflow-y:auto;max-height:var(--height);padding:0 20px 32px;box-sizing:border-box}
.body_drop .tit_drop + .desc_drop,
.body_drop .list_drop + .list_drop{margin-top:4px}
.body_drop .desc_drop + .tit_drop,
.body_drop .desc_drop + .list_drop{margin-top:16px}
.body_drop .tit_drop{display:block;font-weight:600;font-size:16px;line-height:24px;color:#111}
.body_drop .desc_drop{font-weight:500;font-size:14px;line-height:22px;color:#666}
.body_drop .list_drop{overflow:hidden}
.body_drop .list_drop dt,
.body_drop .list_drop dd{float:left;width:50%;font-weight:500;font-size:14px;line-height:22px;color:#111}
.body_drop .list_drop dd{text-align:right}

/* .list_option{overflow:hidden;overflow-y:auto;max-height:var(--height);padding-bottom:32px} */

.btn_option{position:relative;display:block;overflow:hidden;width:100%;padding:16px 0;text-align:left;letter-spacing:-.02em;white-space:nowrap}
.btn_option:disabled{cursor:default;color:#999999}
.btn_option .icon_check{position:absolute;top:50%;right:16px;margin-top:-12px}
.btn_option .txt_option{display:block;font-weight:700;font-size:16px;line-height:24px;color:#666666;word-wrap:break-word;word-break:keep-all;white-space:normal}
.btn_option_img{padding-left:52px}
.btn_option_img .img_option{position:absolute;top:16px;left:0;width:40px;height:40px;background-size:40px;box-sizing:border-box}

.btn_option_img{min-height:72px;font-size:0;white-space:nowrap}
.btn_option_img:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.btn_option_img .txt_option{display:inline-block;vertical-align:middle;white-space:normal}

.area_empty{padding:28px 0}
.area_empty .desc_empty{color:#AAAAAA;text-align:center}

.layer_comm.layer_comm_bottom.active{overflow:visible;background-color:rgba(0, 0, 0, 0.6);opacity:1;transition:background-color 0.6s, opacity 0.6s;}
.layer_comm.layer_comm_bottom.active .inner_layer_comm{max-height:1000px;transition:max-height 0.6s ease-out}
/* position:fixed;z-index:10; */

.layer_comm_full.active .inner_layer_comm{top:0;bottom:0;overflow-y:auto;max-height:100%;border-radius:0}
.layer_comm_full.active .head_drop{position:fixed;top:0;z-index:300;width:100%;height:54px;padding:15px 56px;background-color:#fff;box-sizing:border-box}
.layer_comm_full.active .body_drop{min-height:100%;max-height:initial;padding-top:54px;box-sizing:border-box}
.layer_comm_full.active .btn_close {position:absolute;top:0;right:0;padding:15px 20px}


.layer_comm_scroll_full.active .inner_layer_comm,
.layer_comm.layer_comm_scroll_full.layer_comm_bottom.active .inner_layer_comm{overflow:hidden;overflow-y:auto;max-height:var(--height)}
.layer_comm_scroll_full .body_drop{overflow:hidden;max-height:initial}

/* dragable */
.layer_comm.layer_comm_bottom.layer_comm_dragable .inner_layer_comm{top:100%;transition:top 0.3s ease-out}
/* area_drag */
.area_drag{position:absolute;top:0;left:0;width:100%;height:4px;padding:8px;text-align:center}
.area_drag .icon_drag{display:inline-block;width:40px;height:4px;border-radius:100px;background-color:#D9D9D9;vertical-align:top}
</style>