import Vue from "vue";
import { setEmptyDate } from '@/utils/validateUtils'
import { removeHtmlTag, addComma, wordToUpperCase, wordToLowerCase, wordToCamal, camelToSnake, endWord } from '@/utils/stringUtils'
import { dateDiff, dateTimeDiff, getDayToWeek, dayNight } from '@/utils/dateUtils';
import { setDataList, convertIdToText, getSelectDataList, addDataListAll, convertIdToColor } from "@/utils/selectDataUtils";
// import { hexToRgb } from "@/utils/colorUtils";


// 날짜 포멧
Vue.filter("convertDateFormat", (value, format) => {
  value = setEmptyDate(value);
  if (value) {
      if (!format) {
          format = 'YYYY-MM-DD HH:mm:ss'
      }
      return Vue.moment(value, 'YYYY-MM-DD HH:mm:ss').format(format);
  }
  return '-'
})

// 없는 날짜 설정
Vue.filter("setEmptyDate", (value) => {
  if (value && value != '-') {
    return setEmptyDate(value)
  }
  return '-'
})

// 날짜 비교 (일자 기준)
Vue.filter("dateDiff", (value1,value2) => {
  if (value1 && value2) {
    return dateDiff(value1,value2)
  }
  return '-'
})

// 날짜 비교 (일시 기준)
Vue.filter("dateTimeDiff", (value1,value2) => {
  if (value1 && value2) {
    return dateTimeDiff(value1,value2)
  }
  return '-'
})

// 날짜의 요일
Vue.filter("getDayToWeek", (value) => {
  if (value) {
    return getDayToWeek(value)
  }
  return '-'
})

// 시간의 오전/오후
Vue.filter("dayNight", (value) => {
  if (value) {
    return dayNight(value)
  }
  return '-'
})

// 없는 데이터 설정
Vue.filter("emptyValue", (value,unit ,emptyValueText) => {
  unit = unit ? unit : ''
  if (value && value != '-' && value != '0' && value != 0) {
    return value+unit
  }
  return emptyValueText || '-'
})

// '\n' -> '<br>'
Vue.filter("convertLineFeed", (value, emptyValue) => {
  if(!value) return emptyValue || '-';
  return value.replace(/(?:\r\n|\r|\n)/g, '<br />')
})

// 01012345678 -> 010-1234-5678
Vue.filter("convertPhoneNember", (value) => {
  if(!value) return '-';
  // return value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  return value.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
})

Vue.filter('removeHtmlTag', (value) => {
  return removeHtmlTag(value);
})

// ex - 5000000 -> 5,000,000
Vue.filter("convertNumberToComma", (value, isDotFormat, emptyValueText, unit) => {
  if(!value) return emptyValueText || '-';
  return addComma(value, isDotFormat) + (  unit ? unit : '' );
})

// 퍼센트 구하기
Vue.filter("convertPersent", (value, total, mathType, count) => {
  if(!value) return 0;
  // ceil - 올림
  // floor - 버림
  // round - 반올림
  // toFixed - 소수점 count 갯수만큼만 자르기
  const persent = ( value / total )*100 || 0;
  if(mathType === 'toFixed'){
    return persent.toFixed(count || 2)
  }else if(mathType){
    return Math[mathType](persent);
  }else{
    persent
  }
})

// // selectDataList dataList 바인딩용
Vue.filter("setDataList", (dataList, idKeyName, textKeyName, infoKeyName ) => {
  return setDataList(dataList, idKeyName, textKeyName, infoKeyName);
})

// // selectDataList dataList 바인딩용
Vue.filter("getSelectDataList", (value) => {
  return getSelectDataList(value);
})

// // dataList 바인딩용
Vue.filter("setSelectDataList", (dataList, idKeyName, valueKeyName) => {
  let result = [];
  result = dataList;
  result = result.map(item => {
    let rObj = {};
    rObj.id = item[idKeyName];
    rObj.text = `${item[valueKeyName]}`;
    return rObj;
  });
  return result;
})

// // selectDataList Value의 text값 바인딩용
Vue.filter("convertIdToText", (value, dataListName, cutLength) => {
  return convertIdToText(value, dataListName, cutLength);
})

// // 전체항목 추가
Vue.filter("addDataListAll", (dataName, id, text) => {
  return addDataListAll(getSelectDataList(dataName), id, text);
})

// // colortDataList Value의 color값 바인딩용
Vue.filter("convertIdToColor", (value, dataListName, prev, anotherClassName) => {
  return convertIdToColor(value, dataListName, prev, anotherClassName);
})

// color hex -> rgb
// Vue.filter("hexToRgb", (hex, opacity) => {
//   return hexToRgb ( hex, opacity );
// })


// 영문 대문자 변경
Vue.filter("wordToUpperCase", (value, charAtCount) => {
  return wordToUpperCase(value, charAtCount);
})

// 영문 소문자 변경
Vue.filter("wordToLowerCase", (value, charAtCount) => {
  return wordToLowerCase(value, charAtCount);
})

// camal 형식으로 변경
Vue.filter("wordToCamal", (value,currentJoinType,joinType,startIndex) => {
  return wordToCamal(value,currentJoinType,joinType,startIndex);
})

// camal -> snake 형식으로 변경
Vue.filter("camelToSnake", (value) => {
  return camelToSnake(value);
})

// 마지막 글자 가져오기
Vue.filter("endWord",(korStr, type) => {
  return endWord(korStr, type);
})

// 배열 문자열 -> XXX..외 2건
Vue.filter("arrayToEllip",(value, showValueCount) => {
  showValueCount = showValueCount || 1;
  const totalCount = value.length;
  const etcCount = totalCount - showValueCount;
  const showValueText = value.slice(0,showValueCount).join(', ');
  // let result = '';
  // switch(exportType){
  //   case 'TEXT' : {
  //     result = showValueText;
  //     break
  //   }
  //   case 'COUNT' : {
  //     result = etcCount;
  //     break
  //   }
  // }
  return {
    text : showValueText,
    count : etcCount
  };
})