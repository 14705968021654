var routerPath = {
    WALLET_MAIN_PARENTS: '/wallet/main/parents',
    WALLET_MAIN_CHILD: '/wallet/main/child',
    TRANS_LIST_PARENTS: '/trans/list/parents',
    TRANS_LIST_PARENTS_CHILD: '/trans/list/parents/child_detail',
    TRANS_LIST_CHILD: '/trans/list/child',
    TRANS_DETAIL: '/trans/detail/:id',
    COUPON_LIST: '/coupon/list',
    CHILD_DETAIL: '/child_detail',
    LEAVE_SERVICE: '/leave',
    WEB_SESSION_ERROR: '/web-session-error',
    WORLD_UPDATE_LANDING: '/world-update',
    WORLD_UPDATE_INTRODUCE: '/world-update-introduce',
    WORLD_UPDATE_END: '/world-update-end',
    // v2
    WALLET_MAIN_CHILD_V2: '/v2/wallet/main/child',
    TRANS_LIST_PARENTS_V2: '/v2/trans/list/parents',
    TRANS_LIST_PARENTS_CHILD_V2: '/v2/trans/list/parents/child_detail',
    TRANS_LIST_CHILD_V2: '/v2/trans/list/child',
    TRANS_DETAIL_V2: '/v2/trans/detail/:id',
    TRANS_DETAIL_MEMO: '/v2/trans/:id/memo',
    PIGGY_BANK_INTRODUCTION: '/savings/introduction',
    PIGGY_BANK_GUIDE: '/savings/guide',
    EMPTY: '/empty',
};
export default routerPath;
