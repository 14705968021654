import Vue from "vue";
import { make00 } from "@/utils/stringUtils"

// 날짜 포멧
export function convertDateFormat(value, format) {
  if (value) {
    if (!format) { format = 'YYYY-MM-DD' }
    return Vue.moment(value, 'YYYY-MM-DD HH:mm:ss').format(format);
  }
  return '-'
}

// 날짜 비교 (일별 기준)
export function dateDiff (value1,value2) {
  if (value1 && value2) {
    var val1 = Vue.moment(value1).startOf('day');
    var val2 = Vue.moment(value2).startOf('day');
    const diffData = val1.diff(val2, 'days', true);
    return diffData;
  }
  return '-'
}

// 시간 비교 (시간 기준)
export function timeDiff (value1,value2) {
  if (value1 && value2) {
    // const diff_s = Vue.moment(value1).diff(Vue.moment(value2), 'seconds');
    // const result = Vue.moment.utc(Vue.moment.duration(diff_s, 'seconds').asMilliseconds()).format("hh:mm:ss");
    // return result;

    var value1Unix = Vue.moment(value1).unix();
    var value2Unix = Vue.moment(value2).unix();
    var leftTime = value1Unix - value2Unix;
    var duration = Vue.moment.duration(leftTime, 'seconds');
    duration = Vue.moment.duration(duration.asSeconds(), 'seconds');
    var timer = {
      hours : (duration.hours() < 10) ? '0' + duration.hours() : duration.hours(),
      minutes : (duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes(),
      seconds : (duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds()
    }
    if(duration.asMilliseconds() < 0){
      return null;
    }
    return timer.hours + ':' + timer.minutes + ':' +  timer.seconds
  }
  return '-'
}

// 날짜 비교 (일시 기준)
export function dateTimeDiff (value1,value2) {
  if (value1 && value2) {
    var val1 = Vue.moment(value1).startOf('hour');
    var val2 = Vue.moment(value2).startOf('hour');
    const diffData = val1.diff(val2, 'hours', true);
    return diffData;
  }
  return '-'
}

// 날짜 계산
export function calcDate (value, addVal, type, format) {
  //type : ['day','month']
  if (value && addVal && type) {
    if (!format) { format = 'YYYY-MM-DD' }
    if(addVal > 0){
      return Vue.moment(value).add(addVal, type).format(format);
    }else{
      return Vue.moment(value).subtract(addVal*-1, type).format(format);
    }
  }
  return '-'
}

// 시간 한글로 00:00:00 -> 자정 | 13:00:00 -> 오후 1시 정각
export function timeToKorean (value) {
  if(!value) return
  const timeVal = timeStringToObj(convertDateFormat(value, 'HH:mm:ss'));
  if(timeVal.H === '00' && timeVal.M === '00' && timeVal.S === '00'){
    return '자정'
  }else if(timeVal.M === '00' && timeVal.S === '00'){
    return `${dayNight(timeVal.H)} ${timeVal.H}시 정각`;
  }else if(timeVal.S === '00'){
    return `${dayNight(timeVal.H)} ${timeVal.H}시 ${timeVal.M}분`;
  }else{
    return `${dayNight(timeVal.H)} ${timeVal.H}시 ${timeVal.M}분 ${timeVal.S}초`;
  }
}

export function timeObjToString(valObj){
  return `${valObj.H || '00'}:${valObj.M || '00'}:${valObj.S || '00'}`;
}

export function timeStringToObj(val){
  const valList = val.split(':');
  return { H:Number(valList[0]) || '00', M:Number(valList[1]) || '00', S:Number(valList[2]) || '00' }
}

export function dayNight(hourVal){
  return Number(hourVal) > 11 ? '오후' : '오전'
}


// 날짜기준 요일 가져오기
export function getDayToWeek (value) {
  const weekDataList = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const weekKoreanDataList = ['일','월','화','수','목','금','토'];
  if (value) {
    const weekIndex = Vue.moment(value, 'YYYY-MM-DD').day();
    return weekKoreanDataList[weekIndex];
  }
  return '-'
}


// 날짜기준 요일의 날짜 가져오기
export function getweekDay (value, weekyDay, format) {
  if (value && weekyDay) {
    if (!format) { format = 'YYYY-MM-DD' }
      return Vue.moment(value, 'YYYY-MM-DD HH:mm').day(weekyDay).format(format);
  }
  return '-'
}

// 날짜기준 일주일 날짜 가져오기
export function getWeeklyDateList (value, format) {
  const weekDataList = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const weekKoreanDataList = ['일','월','화','수','목','금','토'];
  if (value) {
    if (!format) { format = 'YYYY-MM-DD' }
    const result = [];
    weekDataList.forEach((item, index) => {
      const obj = {};
      obj.date = Vue.moment(value, 'YYYY-MM-DD HH:mm').day(item).format(format);
      obj.weekday = weekKoreanDataList[index];
      result.push(obj)
    });
    return result;
  }
  return '-'
}

// 월기준 마지막 날짜 가져오기
export function getMonthLastDate (value, format) {
  if (value) {
    const lastDay = Vue.moment(value, "YYYY-MM").daysInMonth();
    return `${value}-${make00(lastDay)}`
  }
  return '-'
}

// 월기준 한달 날짜 가져오기
export function getMonthlyDateList (value, format) {
  // const weekDataList = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  // const weekKoreanDataList = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'];
  if (value) {
    if (!format) { format = 'YYYY-MM-DD' }
    const lastDay = Vue.moment(value, "YYYY-MM").daysInMonth();
    const result = [];
    [...Array(lastDay).keys()].forEach(day => {
      result.push(`${value}-${make00(day+1)}`)
    })
    return result;
  }
  return '-'
}

export function calcNumber (value1, value2){
  if(value1 === value2){
    return 0
  }else if(value1 > value2){
    return 1
  }else if(value1 < value2){
    return -1
  }
}
