import Vue from 'vue';
import Router from 'vue-router';
// import menuId from '@/constants/menuId';
import routerPath from './routerPath';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: routerPath.WALLET_MAIN_PARENTS,
      name: 'WalletMainParents',
      meta: {
        pageTitle:'지갑메인',
      },
      component: () => import('@/views/wallet/view/parents/WalletMain.vue'),
    },
    {
      path: routerPath.WALLET_MAIN_CHILD,
      name: 'WalletMainChild',
      meta: {
        pageTitle:'지갑메인',
      },
      component: () => import('@/views/wallet/view/child/WalletMain.vue'),
    },
    {
      path: routerPath.TRANS_LIST_PARENTS,
      name: 'ParentsTransList',
      meta: {
        pageTitle:'거래내역 목록',
      },
      component: () => import('@/views/transaction/view/parents/ParentsTransList.vue'),
    },
    {
      path: routerPath.TRANS_LIST_PARENTS_CHILD,
      name: 'ParentsTransListChild',
      meta: {
        pageTitle:'자녀의 거래내역 목록',
      },
      component: () => import('@/views/transaction/view/parents/ParentsTransListChild.vue'),
    },
    {
      path: routerPath.TRANS_LIST_CHILD,
      name: 'ChildTransList',
      meta: {
        pageTitle:'거래내역 목록',
      },
      component: () => import('@/views/transaction/view/child/ChildTransList.vue'),
    },
    {
      path: routerPath.TRANS_DETAIL,
      name: 'TransDetail',
      meta: {
        pageTitle:'거래내역 상세',
      },
      component: () => import('@/views/transaction/view/TransDetail.vue'),
    },
    {
      path: routerPath.COUPON_LIST,
      name: 'CouponList',
      meta: {
        pageTitle:'쿠폰함',
      },
      component: () => import('@/views/coupon/view/CouponList.vue'),
    },
    {
      path: routerPath.CHILD_DETAIL,
      name: 'ChildDetail',
      meta: {
        pageTitle:'내 자녀의 상세',
      },
      component: () => import('@/views/childDetail/view/ChildDetail.vue'),
    },
    {
      path: routerPath.LEAVE_SERVICE,
      name: 'LeaveService',
      meta: {
        pageTitle:'탈퇴하기',
      },
      component: () => import('@/views/leave/view/LeaveServiceLayout.vue'),
    },
    {
      path: routerPath.WEB_SESSION_ERROR,
      name: 'WebSessionError',
      meta: {
        pageTitle:'범용에러',
      },
      component: () => import('@/views/error/view/WebSessionError.vue'),
    },
    {
      path: routerPath.WORLD_UPDATE_LANDING,
      name: 'WorldUpdateLanding',
      meta: {
        pageTitle:'퍼핀월드 업데이트 렌딩페이지',
      },
      component: () => import('@/views/worldUpdate/view/WorldUpdateLanding.vue'),
    },
    {
      path: routerPath.WORLD_UPDATE_INTRODUCE,
      name: 'WorldUpdateIntroduce',
      meta: {
        pageTitle:'퍼핀월드 업데이트 소개페이지',
      },
      component: () => import('@/views/worldUpdate/view/WorldUpdateIntroduce.vue'),
    },
    {
      path: routerPath.WORLD_UPDATE_END,
      name: 'WorldUpdateEnd',
      meta: {
        pageTitle:'퍼핀월드 업데이트 종료',
      },
      component: () => import('@/views/worldUpdate/view/WorldUpdateEnd.vue'),
    },
    
    // v2
    {
      path: routerPath.WALLET_MAIN_CHILD_V2,
      name: 'WalletMainChildV2',
      meta: {
        pageTitle:'자녀 지갑메인',
      },
      component: () => import('@/views/v2/wallet/view/child/WalletMain.vue'),
    },
    {
      path: routerPath.TRANS_LIST_PARENTS_V2,
      name: 'ParentsTransListV2',
      meta: {
        pageTitle:'부모의 거래내역 목록',
      },
      component: () => import('@/views/v2/transaction/view/parents/ParentsTransList.vue'),
    },
    {
      path: routerPath.TRANS_LIST_PARENTS_CHILD_V2,
      name: 'ParentsTransListChildV2',
      meta: {
        pageTitle:'부모가 보는 자녀의 거래내역 목록',
      },
      component: () => import('@/views/v2/transaction/view/parents/ParentsTransListChild.vue'),
    },
    {
      path: routerPath.TRANS_LIST_CHILD_V2,
      name: 'ChildTransListV2',
      meta: {
        pageTitle:'자녀의 거래내역 목록',
      },
      component: () => import('@/views/v2/transaction/view/child/ChildTransList.vue'),
    },
    {
      path: routerPath.TRANS_DETAIL_V2,
      name: 'TransDetailV2',
      meta: {
        pageTitle:'거래내역 상세',
      },
      component: () => import('@/views/v2/transaction/view/TransDetail.vue'),
    },
    {
      path: routerPath.TRANS_DETAIL_MEMO,
      name: 'TransDetailMemo',
      meta: {
        pageTitle:'거래내역 상세 메모',
      },
      component: () => import('@/views/v2/transaction/view/TransDetailMemo.vue'),
    },
    {
      path: routerPath.PIGGY_BANK_INTRODUCTION,
      name: 'PiggyBankIntroduction',
      component: () =>
        import('@/views/piggyBank/introduction/view/PiggyBankIntroduction.vue'),
    },
    {
      path: routerPath.PIGGY_BANK_GUIDE,
      name: 'PiggyBankGuide',
      component: () =>
        import('@/views/piggyBank/guide/view/PiggyBankGuide.vue'),
    },
    {
      path: routerPath.EMPTY,
      name: 'Empty',
      component: () => import('@/views/empty/view/Empty.vue'),
    },
    { path: '*', redirect: routerPath.WALLET_MAIN_PARENTS },
  ]
});

router.beforeEach((to, _from, next) => {
  const disabled = to.meta.disabled;
  if(disabled && process.env.NODE_ENV === 'production'){
    next({ path: routerPath.WALLET_MAIN_PARENTS });
    return;
  }
  next()
})

export default router
