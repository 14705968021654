const IconSvgPath = {
  back: 'M12,4 L13.42,5.41 L7.83,11 L20,11 L20,13 L7.83,13 L13.41,18.59 L12,20 L4,12 L12,4 Z',
  cross: 'M17.59,5 L19,6.41 L13.41,12 L19,17.59 L17.59,19 L12,13.41 L6.41,19 L5,17.59 L10.59,12 L5,6.41 L6.41,5 L12,10.59 L17.59,5 Z',
  search: 'M19.2543,20.7457 L14.2822,15.7822 C13.2141,16.5496 11.9067,17 10.5,17 C6.91,17 4,14.09 4,10.5 C4,6.91 6.91,4 10.5,4 C14.09,4 17,6.91 17,10.5 C17,11.9067 16.5496,13.2141 15.7822,14.2822 L20.7457,19.2543 C20.7661,19.2747 20.7855,19.2958 20.8039,19.3174 L19.3174,20.8039 C19.2958,20.7855 19.2747,20.7661 19.2543,20.7457 Z M10.5,15 C8.01,15 6,12.99 6,10.5 C6,8.01 8.01,6 10.5,6 C12.99,6 15,8.01 15,10.5 C15,12.99 12.99,15 10.5,15 Z',
  remove: 'M12,2 C6.47,2 2,6.47 2,12 C2,17.53 6.47,22 12,22 C17.53,22 22,17.53 22,12 C22,6.47 17.53,2 12,2 Z M17,15.59 L15.59,17 L12,13.41 L8.41,17 L7,15.59 L10.59,12 L7,8.41 L8.41,7 L12,10.59 L15.59,7 L17,8.41 L13.41,12 L17,15.59 Z',
  arrow_fold: 'M16.59,8.58984 L18,9.99984 L12,15.9998 L6,9.99984 L7.41,8.58984 L12,13.1698 L16.59,8.58984 Z',
  arrow_left: 'M14,6 L15.41,7.41 L10.83,12 L15.41,16.59 L14,18 L8,12 L14,6 Z',
  arrow_right: 'M9.41,6 L15.40996,12 L9.41,18 L8,16.59 L12.57996,12 L8,7.41 L9.41,6 Z',
  check: 'M19.59004,5.59 L21.00004,7 L9,18.99997 L3.41,13.40997 L4.83,11.99997 L9,16.16997 L19.59004,5.59 Z',
  guide: 'M2,12 C2,6.47 6.47,2 12,2 C17.53,2 22,6.47 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 Z M10.75,16.74 C10.75,17.44 11.31,18 12.01,18 C12.72,18 13.25,17.43 13.26,16.74 C13.26,16.03 12.72,15.49 12.01,15.49 C11.31,15.49 10.75,16.03 10.75,16.74 Z M13.9185,11.862 C14.2488,11.5408 14.6291,11.1709 15.02,10.6 C15.7,9.59 15.44,8.24 15,7.52 C14.48,6.67 13.47,6 11.99,6 C10.22,6 9.01,7.15 8.56,8.49 L10.21,9.19 C10.43,8.51 10.95,7.71 12.01,7.71 C13.63,7.71 13.95,9.23 13.38,10.04 C13.1389,10.3838 12.82,10.6778 12.4973,10.9753 C12.0972,11.3441 11.6912,11.7184 11.42,12.2 C11.0831,12.7961 11.0969,13.4744 11.1069,13.9638 C11.1085,14.0409 11.11,14.1133 11.11,14.18 L12.93,14.18 C12.93,13.25 12.99,13.06 13.15,12.77 C13.3394,12.4252 13.6067,12.1653 13.9185,11.862 Z',
}
export default IconSvgPath;