<template>
  <div id="app">
    <router-view></router-view>
    <ToastPopup
      v-if="getIsToastPopup"
      :toastType="getIsToastType"
      :toastMsg="getIsToastMsg"
      @onComplete="onCompleteToast" />
    <AlertPopup
      v-if="getIsAlertPopup"
      :alertMsg="getIsAlertMsg"
      :closeBtnText="getIsCloseBtnText"
      :compeleteBtnText="getIsCompeleteBtnText"
      :closeBtnStyle="getIsCloseBtnStyle"
      :compeleteBtnStyle="getIsCompeleteBtnStyle"
      @onClickClose="onCloseAlert"
      @onClickComplete="onCompleteAlert" />
    <DropBoxSelect v-if="getIsSelectDropBox"/>
  </div>
</template>

<script>
import ToastPopup from '@/components/layout/popup/ToastPopup';
import AlertPopup from '@/components/layout/popup/AlertPopup';
import DropBoxSelect from '@/components/layout/popup/DropBoxSelect';
import { mapGetters, mapActions } from 'vuex';
import { GET_REQUEST } from '@/services/http.js';

export default {
  name: 'App',
  components:{
    ToastPopup,
    AlertPopup,
    DropBoxSelect,
  },
  beforeMount(){
    window.backKeyEvent = (count) => {
      if(this.getIsAlertPopup){
        if(this.getIsCloseBtnText){
          this.onCloseAlert();
        }else{
          this.onCompleteAlert();
        }
      }else if(this.getIsSelectDropBox){
        this.fetchDropBoxEnd();
      }else{
        this.fetchBackKeyEvent(true);
      }
    }
    this.onResize();
    // this.getMeData();
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.onResize);
  },
  computed:{
    ...mapGetters('commonToast', [ 'getIsToastPopup','getIsToastMsg','getIsToastType' ]),
    ...mapGetters('commonAlert', [ 'getIsAlertPopup','getIsAlertMsg','getIsCloseBtnText','getIsCompeleteBtnText','getIsCloseBtnStyle','getIsCompeleteBtnStyle','getConfirmCallBack', 'getCloseButtonCallback' ]),
    ...mapGetters('commonDropBox', [ 'getIsSelectDropBox','getIsDateDropBox', 'getIsTimeDropBox']),
  },
  methods:{
    ...mapActions('commonToast', [ 'fetchToastEnd' ]),
    ...mapActions('commonAlert', [ 'fetchAlertEnd' ]),
    ...mapActions('commonViewport', [ 'fetchViewPort' ]),
    ...mapActions('commonDropBox', [ 'fetchDropBoxEnd' ]),
    ...mapActions('commonInterface', [ 'fetchBackKeyEvent' ]),
    onResize(){
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.fetchViewPort({ width: width, height: height });
    },
    onCompleteToast() {
      this.fetchToastEnd();
    },
    onCompleteAlert(){
      this.fetchAlertEnd(true);
      if (this.getConfirmCallBack) {
        this.getConfirmCallBack();
      }
    },
    onCloseAlert(){
      this.fetchAlertEnd(false);
      if (this.getCloseButtonCallback) {
        this.getCloseButtonCallback();
      }
    },
    // getMeData(){
    //   const path = `${this.$apiPath.ME_INFO}`;
    //   let resultData = {};
    //   GET_REQUEST(path).then(
    //   (success) => {
    //     resultData = success.data.body.user;
    //     LocalStorageManager.shared.setUserData(resultData);
    //     this.$store.dispatch('commonUserData/fetchUserData', resultData);
    //   }, (failed) => {
    //     // this.$store.dispatch('commonToast/fetchToastStart', failed.msg );
    //   })
    //   LocalStorageManager.shared.setUserData(resultData);
    // }
  },
}
</script>
