<template>
  <div class="lemontree_layer" @click.self="onClickClose">
    <div
      :class="['inner_comm_layer', 'inner_alert_layer']">
      <div class="layer_body" ref="layerBody">
        <p class="desc_alert" v-html="alertMsg"></p>
      </div>
      <div class="layer_foot">
        <div :class="['group_btn']">
          <Button
            v-if="compeleteBtnText"
            btnSize="medium"
            :btnStyle="compeleteBtnStyle"
            :text="compeleteBtnText"
            @onClickBtn="onClickComplete" />
          <Button
            v-if="closeBtnText"
            btnSize="medium"
            :btnStyle="closeBtnStyle ? closeBtnStyle : compeleteBtnText ? 'secondary_tonal' : 'tertiary_tonal'"
            :text="closeBtnText"
            @onClickBtn="onClickClose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/button/Button';

export default {
  name: 'AlertPopup',
  components:{
    Button
  },
  props:{
    alertMsg: String,
    closeBtnText: String,
    compeleteBtnText: String,
    closeBtnStyle: String,
    compeleteBtnStyle: {
      type: String,
      default:'tertiary_tonal'
    },
  },
  mounted(){
    document.body.style.overflow = "hidden";
  },
  unmounted(){
    document.body.style.overflow = "";
  },
  methods:{
    onClickClose(){
      document.body.style.overflow = "";
      this.$emit('onClickClose');
    },
    onClickComplete(){
      document.body.style.overflow = "";
      this.$emit('onClickComplete');
    }
  }
}
</script>

<style scoped>
/* -- 레이어 팝업 -- */
.lemontree_layer{position:fixed;top:0;bottom:0;left:0;right:0;padding:40px;z-index:9999;min-width:100%;font-size:0;background-color:rgba(34,34,34,.6);backdrop-filter:blur(1px);text-align:center;white-space:nowrap;box-sizing:border-box;}
.lemontree_layer:after{display:inline-block;height:100%;vertical-align:middle;content:''}

/* .inner_comm_layer{display:inline-block;position:relative;width:80%;max-width:650px;max-height:calc(100vh - 100px);min-height:400px;padding:30px 0 65px;border-radius:2px;background-color:#fff;white-space:normal;vertical-align:middle;box-sizing:border-box} */

.lemontree_layer .inner_comm_layer{display:inline-block;overflow:hidden;position:relative;padding:0 0 24px;border-radius:8px;background-color:#fff;white-space:normal;vertical-align:middle;text-align:left}


.lemontree_layer .inner_alert_layer{max-width:424px;min-width:280px}
.lemontree_layer .inner_alert_layer .layer_body{padding:24px 20px 16px;word-wrap:break-word;word-break:keep-all}
.lemontree_layer .inner_alert_layer .layer_body .desc_alert{font-weight:500;font-size:14px;line-height:22px;color:#666}
.lemontree_layer .inner_alert_layer .layer_body .desc_alert >>> strong{display:block;margin-bottom:16px;font-weight:700;font-size:20px;line-height:28px;color:#111}
.lemontree_layer .inner_alert_layer .layer_body .group_btn{padding-top:40px}

.lemontree_layer .inner_alert_layer .layer_foot{padding:0 20px}


/* 모바일 */
@media all and (max-width:360px){
.lemontree_layer .inner_alert_layer{min-width:100%}
}
</style>