const state = {
  isAlertPopup: false,
  alertMsg: null,
  closeBtnText: '',
  compeleteBtnText: '',
  closeBtnStyle: '',
  compeleteBtnStyle: '',
  // isConfirm: null,
  // alertCallBack:'',
  confirmCallBack: null,
  closeButtonCallback: null,
}

const getters = {
	getIsAlertPopup: (state) => state.isAlertPopup,
	getIsAlertMsg: (state) => state.alertMsg,
	getIsCloseBtnText: (state) => state.closeBtnText,
	getIsCompeleteBtnText: (state) => state.compeleteBtnText,
	getIsCloseBtnStyle: (state) => state.closeBtnStyle,
	getIsCompeleteBtnStyle: (state) => state.compeleteBtnStyle,
	// // getIsConfirm: (state) => state.isConfirm,
	getConfirmCallBack: (state) => state.confirmCallBack,
  getCloseButtonCallback: (state) => state.closeButtonCallback,
}

const actions = {
  fetchAlertStart({ commit }, params) {
    commit('START_ALERT_MUTATION', params);
  },
  fetchAlertEnd({ commit }, params) {
    commit('END_ALERT_MUTATION', params);
  },
}

const mutations = {
  START_ALERT_MUTATION(state, payload) {
    const { alertMsg, closeBtnText, compeleteBtnText, closeBtnStyle, compeleteBtnStyle, confirmCallBack, closeButtonCallback } = payload;
    state.alertMsg = alertMsg;
    state.closeBtnText = closeBtnText;
    state.compeleteBtnText = compeleteBtnText;
    state.closeBtnStyle = closeBtnStyle;
    state.compeleteBtnStyle = compeleteBtnStyle;
    state.confirmCallBack = confirmCallBack;
    state.closeButtonCallback = closeButtonCallback;
    state.isAlertPopup = true;
  },
  END_ALERT_MUTATION(state, payload) {
    state.isAlertPopup = false;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}