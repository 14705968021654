const apiPath = {
  ME_INFO : "/api/user/v1/me",

  WALLET_MAIN : "/api/wallet/v1/main", // (부모) 지갑 메인
  WALLET_MAIN_CHILD : "/api/wallet/v2/child/main", // (자녀) 지갑 메인

  TRANS_LIST_PARENTS : "/api/wallet/v1/transaction/list", // (부모) 거래내역
  TRANS_LIST_CHILD : "/api/wallet/v2/transaction/list", // (자녀) 거래내역
  TRANS_LIST_PARENTS_CHILD: "/api/wallet/v2/child/%s/transaction/list", // [ childUserUid ] (부모가 보는 자녀) 거래내역
  
  TRANS_DETAIL_PARENTS : "/api/wallet/v1/transaction/%s/detail", // [ transactionUid ] (부모) 거래내역 상세
  TRANS_DETAIL_CHILD : "/api/wallet/v2/transaction/%s/detail", // [ transactionUid ] (자녀) 거래내역 상세
  TRANS_DETAIL_PARENTS_CHILD : "/api/wallet/v2/child/%s/transaction/%s/detail", // [ childUserUid, transactionUid ] (부모가 보는 자녀) 거래내역 상세
  
  TRANS_DETAIL_MEMO : "/api/wallet/v1/transaction/%s/memo", // [ transactionUid ]

  COUPON_LIST : "/api/coupon/register/v1/coupons",
  COUPON_REGISTER : "/api/coupon/register/v1/register",
  COUPON_APPLY : "/api/coupon/register/v1/apply",

  FAMILY_MEMBER_LIST : "/api/setting/v1/family/member/list", // 가족 구성원 목록

  LEAVE_SERVICE_VIEW: "/api/user/v1/unregister/view", // 탈퇴 화면

  CHILD_DETAIL : "/api/home/v2/child/%s/detail/view", // [ childUid ]

  CATEGORY_STATISTIC: "/api/wallet/v2/payment-category", // 자신의 결제 카테고리 집계 조회
  CATEGORY_STATISTIC_CHILD: "/api/wallet/v2/child/%s/payment-category", // [childUserUid] 부모가 보는 자녀의 결제 카테고리 집계조회

  // /api/wallet/v2/transaction/{transactionUid}/payment-category
  WALLET_TRANSACTION_CATEGORY: "/api/wallet/v2/transaction/%s/payment-category",

  BANK_COMPANY_ALL_LIST : "/api/bank/v1/company/all-list", // 은행목록
  BANK_ACCOUNT_AUTH_OWNER : "/api/bank/v1/auth/account-auth/owner-name/verify", // 계좌 소유주 확인

  WORLDMAP_UPDATE_FUNNEL: "/api/funnel/v1/funnel/%s/%s", // [funnelType, value] 월드맵 구매 테스트
  WORLDMAP_FEATURE_FLAG: "/open/api/feature-flag/v1/key", // 월드맵 조르기 기간 종료유무
};
export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};