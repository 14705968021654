<template>
  <div :class="['icon_firfin','icon_'+iconName]" :style="cssProps">
    <svg
      class="svg_icon"
      :width="size"
      :height="size"
      xmlns="http://www.w3.org/2000/svg"
      :aria-labelledby="iconName"
      :viewBox="`0 0 24 24`"
      role="presentation"
    >
      <title :id="iconName" lang="en">{{ iconName }} icon</title>
      <g
        :fill="iconColorSync"
        fill-rule="evenodd">
        <path :d="iconSvgPath"/>
      </g>
    </svg>
  </div>
</template>

<script>
import IconSvgPath from "@/components/common/icon/IconSvgPath";

export default {
  name:'IconSvg',
  props: {
    iconName: {
      type: String,
      default: 'logo'
    },
    size: {
      type: [Number, String],
      default: 24
    },
    iconColor: String,
    iconDarkColor: String,
    bgcolor: {
      type: String,
      default: 'none'
    }
  },
  computed:{
    isDarkMode(){
      return  Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    },
    iconColorSync(){
        return this.iconColor || '#fff';
    },
    iconSvgPath(){
      return IconSvgPath[this.iconName];
    },
    cssProps(){
      var obj = {};
      obj = {
        '--width': this.size+'px',
        '--height': this.size+'px',
        '--bgcolor': this.bgcolor,
      }
      return obj;
    },
  }
}
</script>
<style scoped>
.icon_firfin{display:inline-block;overflow:hidden;width:var(--width);height:var(--height);line-height:var(--height);background-color:var(--bgcolor);vertical-align:top}
.icon_firfin svg{display:block}
.icon_firfin g{
  -webkit-transition: fill 0.5s ease-out;
  -moz-transition: fill 0.5s ease-out;
  -o-transition: fill 0.5s ease-out;
  transition: fill 0.5s ease-out;
}
</style>