
import LocalStorageManager from '@/storages/LocalStorageManager';

const state = {
  userData: {},
  role:'',
  userResizeImage: '',
}

const getters = {
	getUserData: () => {
    state.userData = LocalStorageManager.shared.getUserData()
    return state.userData;
  },
	getUserRole: () => {
    const userData = LocalStorageManager.shared.getUserData();
    if(!userData && !state.userData) return '';
    state.role = userData.role || state.userData.role;
    return state.role;
  },
	getUserResizeImg: () => {
    if( !state.userResizeImage && LocalStorageManager.shared.getUserResizeImg() ){
      state.userResizeImage = LocalStorageManager.shared.getUserResizeImg()
    }
    return state.userResizeImage;
  },
}

const actions = {
  fetchUserData({ commit }, params) {
    commit('SET_USER_DATA', params)
  },
  fetchUserResizeImg({ commit }, params) {
    commit('SET_USER_RESIZE_IMG', params)
  },
}

const mutations = {
  SET_USER_DATA(state, payload) {
    state.userData = payload;
    state.role = state.userData.role;
    LocalStorageManager.shared.clearUserData();
    LocalStorageManager.shared.setUserData(payload);
  },
  SET_USER_RESIZE_IMG(state, payload) {
    state.userResizeImage = payload;
    LocalStorageManager.shared.clearUserResizeImg();
    LocalStorageManager.shared.setUserResizeImg(payload);
  }
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}