// import { selectDataList } from '@/constants/selectDataList';
// import { colorDataList } from '@/constants/colorDataList';

export function convertIdToText(value, dataName, cutLength, emptyValueText){
  // console.log(value, dataName, cutLength, emptyValueText);
  let dataList = [];
  if( Array.isArray(dataName) ){
    dataList = dataName;
  }else{
    // dataList = selectDataList[dataName];
  }
  if( value === undefined || value == null || value == 'NONE' || value.length == 0 ){
    return emptyValueText || '-'
  }
  // console.log('convertIdToText INIT : ', value, dataName)
  if( !dataList ){
    // console.log('convertIdToText ERROR : dataName를 확인해주세요', value, dataName, dataList);
    return emptyValueText || '-'
  }
  if(Array.isArray(value)){
    let valueList = [];
    value.forEach(valueItem => {
      const findItem = dataList.find(item => item.id === valueItem);
      if(!findItem){
        // console.log('convertIdToText ERROR : 해당하는 value 값이 없습니다.', value, dataName, dataList);
        return emptyValueText || '-'
      }
      let result = findItem.text;
      if(cutLength){
        result = result.slice(0,cutLength);
      }
      valueList.push(result);
    });
    return valueList.join(', ');
  }else{
    const findItem = dataList.find(item => String(item.id) === String(value));
    // console.log(findItem)
    if(!findItem){
      // console.log('convertIdToText ERROR : 해당하는 value 값이 없습니다.', value, dataName, dataList);
      return emptyValueText || '-'
    }
    let result = findItem.text;
    if(cutLength){
      result = result.slice(0,cutLength);
    }
    return result;
  }
};

export function getSelectDataList(dataName){
  if( !dataName ){
    // console.log('convertIdToText ERROR : dataName를 확인해주세요', value, dataName, dataList);
    return []
  }
  let dataList = [];
  if( Array.isArray(dataName) ){
    dataList = dataName;
  }else{
    // dataList = selectDataList[dataName];
  }
  return dataList
}

export function setDataList(dataList, idKeyName, textKeyName, infoKeyName ){
  const convertDataList = dataList.map(item => {
    let rObj = item;
    rObj.id = item[idKeyName];
    rObj.text = item[textKeyName];
    if(infoKeyName) rObj.info = item[infoKeyName];
    return rObj;
  });
  return convertDataList;
}

export function setCurrentValue(dataList){
  const currentItem = dataList.find(item => item.is_current);
  if(!currentItem) return ''
  return currentItem.value;
}

// export function sortSelectDataList(dataList,dataName){
//   const currentSelectDataList = selectDataList[dataName];
//   let result = [];
//   if( !currentSelectDataList ){
//     // console.log('sortSelectDataList ERROR : dataName를 확인해주세요', dataList, dataName, currentSelectDataList);
//     return dataList
//   }
//   currentSelectDataList.forEach(item => {
//     const findItem = dataList.find(dataListItem => item.id === dataListItem);
//     // console.log(item, findItem)
//     if(findItem){
//       result.push(findItem);
//     }
//   })

//   return result
// }

// dataList 전체항목 추가
export function addDataListAll(value, id, text){
  const allId = id || null;
  const allText = text || '전체'
  const isHasAll = value.find(item => item.text === allText);
  let result = [];
  result = result.concat(value)
  if(!isHasAll){
    result.unshift({id:allId, text:allText});
  }
  return result;
}

// export function convertIdToColor(value, dataName, prev, anotherClassName){
//   let dataList = [];
//   if( Array.isArray(dataName) ){
//     dataList = dataName;
//   }else{
//     dataList = colorDataList[dataName];
//   }
//   if( !value || value == null || value == 'NONE' || value.length == 0 ){ return '-' }
//   // console.log('convertIdToColor INIT : ', value, dataName)
//   if( !dataList ){
//     // console.log('convertIdToColor ERROR : dataName를 확인해주세요', value, dataName, dataList);
//     return '-'
//   }
//   const findItem = dataList.find(item => item.id === value);
//   // console.log(findItem)
//   if(!findItem){
//     // console.log('convertIdToColor ERROR : 해당하는 value 값이 없습니다.', value, dataName, dataList);
//     return '-'
//   }
//   let result = findItem.text;
//   if(prev){
//     result = `${prev}_${result}`;
//   }
//   if(anotherClassName){
//     result = `${result} ${anotherClassName}`;
//   }
//   return result;
// };