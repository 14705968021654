const state = {
  backKeyEvent: false,
  backKeyEventCount: 0
}

const getters = {
	getIsBackKeyEvent: (state) => state.backKeyEvent,
  getBackKeyEventCount: (state) => state.backKeyEventCount,
}

const actions = {
  fetchBackKeyEvent({ commit }, params) {
    commit('BACK_KEY_EVENT_MUTATION', params);
  },
}

const mutations = {
  BACK_KEY_EVENT_MUTATION(state, payload) {
    state.backKeyEvent = payload;
    if(payload){
      state.backKeyEventCount += 1;
    }
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}