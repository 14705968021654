const state = {
  uid: null,
  isSelectDropBox: false,
  isDateDropBox: false,
  isTimeDropBox: false,
  target: undefined,
  targetHeight:null,
  option: {},
  dropBoxDataList: [],
  selectData: {},
  value: undefined,
}

const getters = {
	getDropBoxUid: (state) => state.uid,
	getIsSelectDropBox: (state) => state.isSelectDropBox,
	getIsDateDropBox: (state) => state.isDateDropBox,
  getIsTimeDropBox: (state) => state.isTimeDropBox,
	getDropBoxTarget: (state) => state.target,
	getDropBoxTargetHeight: (state) => state.targetHeight,
	getDropBoxOption: (state) => state.option,
	getDropBoxDataList: (state) => state.dropBoxDataList,
	getDropBoxSeletData: (state) => state.selectData,
	getDropBoxValue: (state) => state.value,
}

const actions = {
  fetchDropBoxStart({ commit, state }, params) {
    // console.log('fetchDropBoxStart',params)
    if(( state.isSelectDropBox || state.isDateDropBox ) && state.uid != params.uid){
      commit('END_DROPBOX_MUTATION');
      setTimeout(() =>  {
        commit('START_DROPBOX_MUTATION', params);
      }, 210);
    }else{
      commit('START_DROPBOX_MUTATION', params);
    }
  },
  fetchDropBoxTargetHeight({ commit }, params) {
    commit('TARGET_HEIGHT_DROPBOX_MUTATION', params);
  },
  fetchDropBoxSelect({ commit, state }, params) {
    commit('SELET_DROPBOX_MUTATION', params);
    // console.log(params, typeof(params) );
    if(params.id){
      commit('VALUE_DROPBOX_MUTATION', params.id);
    }else if(typeof(params) === 'string'){
      commit('VALUE_DROPBOX_MUTATION', params);
    }
  },
  fetchDropBoxValue({ commit }, params) {
    // console.log('fetchDropBoxValue',params)
    commit('VALUE_DROPBOX_MUTATION', params);
  },
  fetchDropBoxEnd({ commit }, params) {
    // console.log('fetchDropBoxEnd',params)
    commit('END_DROPBOX_MUTATION', params);
  },
}

const mutations = {
  START_DROPBOX_MUTATION(state, payload) {
    const { uid, dropType, dataList, target, value, option } = payload;
    state.uid = uid;
    state.dropBoxDataList = dataList;
    state.target = target;
    state.option = option;
    state.value = value;
    switch(dropType){
      case 'select' : { state.isSelectDropBox = true ; break }
      case 'date' : { state.isDateDropBox = true ; break }
      case 'time' : { state.isTimeDropBox = true ; break }
    }
  },
  TARGET_HEIGHT_DROPBOX_MUTATION(state, payload) {
    state.targetHeight = payload;
  },
  SELET_DROPBOX_MUTATION(state, payload) {
    state.selectData = payload;
  },
  VALUE_DROPBOX_MUTATION(state, payload) {
    state.value = payload;
  },
  END_DROPBOX_MUTATION(state) {
    state.isSelectDropBox = false;
    state.isDateDropBox = false;
    state.isTimeDropBox = false;
    state.uid = null;
    // state.selectData = {};
    // state.value = undefined;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}